//
// asset-details.scss
//

.asset-details {
  .img-container {
    padding: 1em 0;
    text-align: center;
    img {
      width: 50%;
      height: auto;
    }
  }
}
