.formik-form-group {
  .form-control {
    margin-bottom: 16px;
    &.error {
      border-color: red;
    }
  }

  .input-group-append {
    margin-bottom: 16px;
  }

  .label-error,
  .invalid-feedback {
    color: red;
  }

  .password-btn {
    cursor: pointer;
    .input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
